// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes,Route, Switch } from 'react-router-dom';
import "./css/App.css";
import 'leaflet/dist/leaflet.css';

import InteractiveWhales from "./InteractiveWhales";
import NoneInteractiveShips from "./NoneInteractiveShips";
import NoneInteractiveWhales from "./NoneInteractiveWhales";
import InteractiveShips from "./InteractiveShips";
import InteractiveShipsNew from "./InteractiveShipsNew";

import { Link } from "react-router-dom";
function App() {
  return (    
        <div className="container">            
              <Routes>
                <Route path="/" element={<NoneInteractiveShips />} /> 
                <Route path="/whales" element={<NoneInteractiveWhales />} />
                <Route path="/interactive-ships" element={<InteractiveShips />} />                
                <Route path="/interactive-whales" element={<InteractiveWhales />} />     
                <Route path="/interactive-ships-new" element={<InteractiveShipsNew />} />
             </Routes>
        </div>
    
    
  );
}

export default App;
